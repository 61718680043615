
.team-full-row .team-image > div {
    position:relative;
    height:100%;
    overflow:hidden;
}
.team-full-row .team-image > div > img {
    transition: opacity 0.5s;
    height:100%;
    width:auto;
    min-width:100%;
    max-width:none;
    margin-left:50%;
    transform: translate(-50%, 0);
}
.team-full-row .team-image > div > img:nth-child(2) {
    position:absolute;
    top:0px;
    left:0px;
}
.team-full-row .team-image:hover > div > img:nth-child(2) {
    opacity: 0;
}
.team-full-row .team-description h4 {
    margin-bottom: 5px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
}
.team-full-row .team-content + .social-medias {
    margin-top:30px;
}
.team-full-row .team-description img.signature {
    position: absolute;
    bottom: 1.5em;
    right: 3em;
    width: 200px;
}


